import React from 'react';
import Navigation from '../components/Navigation';

const Home = () => {
    return (
        <div className="home">
            <Navigation />
            <div className="homeContent">
                <div className="content">
                    <h1>Mostefa Medjahed</h1>
                    <h2>Full Stack Web Developer</h2>
                    {/* <div className="pdf">
                        <a href="./CV.pdf" target="_blank">Download CV</a>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Home;