import React from 'react';
import Navigation from '../components/Navigation';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Contact = () => {
    return (
        <div className="contact">
            <Navigation />
            <div className="contactContent">
                <div className="header"></div>
                <div className="contactBox">
                    <h1>Contact us</h1>
                    <ul>
                        <li>
                            <i className="fas fa-map-marker-alt"></i>
                            <span>Paris region</span>
                        </li>
   {/*                      <li>
                            <i className="fas fa-mobile-alt"></i>
                            <CopyToClipboard text="06 XX 60 XX XX">
                                <span className="clickInput" onClick={() => alert('Phone Copied !')}> 06 XX 60 XX XX</span>
                            </CopyToClipboard>
                        </li> */}
                        <li>
                            <i className="far fa-envelope"></i>
                            <CopyToClipboard text="mostefa.medjahed.pro@gmail.com">
                                <span className="clickInput" onClick={() => alert('Email Copied !')}> mostefa.medjahed.pro@gmail.com</span>
                            </CopyToClipboard>
                        </li>
                    </ul>
                </div>

                <div className="socialNetwork">
                    <ul>
                        <a href="https://www.linkedin.com/in/mostefa-medjahed/" target="_blank" rel="noopener noreferrer">
                            <h4>LinkedIn</h4>
                            <i className="fab fa-linkedin"></i>
                        </a>

                        <a href="https://github.com/mostefamed" target="_blank" rel="noopener noreferrer">
                            <h4>Github</h4>
                            <i className="fab fa-github"></i>
                        </a>

     {/*                    <a href="https://www.google.com" target="_blank" rel="noopener noreferrer">
                            <h4>Twitter</h4>
                            <i className="fab fa-twitter"></i>
                        </a>

                        <a href="https://www.google.com" target="_blank" rel="noopener noreferrer">
                            <h4>Codepen</h4>
                            <i className="fab fa-codepen"></i>
                        </a> */}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Contact;