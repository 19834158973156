import React from 'react';

const Experience = () => {
    return (
        <div className="experience">
            <h3>Experience</h3>
            <div className="exp-1">
                <h4>Full Stack Web Developer - France Télévisions</h4>
                <h5>May 2021 - till now</h5>
                <p>A member of the Lumni platform team, in charge of developing features to enhance the user experience,
                    debugging issues that come up with the application. I contribute in the both sides, back and front.</p>
            </div>
            <div className="exp-2">
                <h4>Senior Backend Web Developer / Team leader - Maisons Du Monde</h4>
                <h5>Jan 2019 - Oct 2020</h5>
                <p>A part of the Backend team and responsible of the products catalog. 
                Analyze, design, develop, deploy as well as implement RESTful services and APIs</p>
            </div>
            <div className="exp-3">
                <h4>Full Stack Web Developer - iDGARAGES.COM</h4>
                <h5>Feb 2016 - Dec 2018</h5>
                <p>A part of the Backend team and responsible of the products catalog. 
                Analyze, design, develop, deploy as well as implement RESTful services and APIs</p>
            </div>
        </div>
    );
};

export default Experience;