import React from 'react';

const OtherSkills = () => {
    return (
        <div className="otherSkills">
            <h3>Others skills</h3>
            <div className="list">
                <ul>
                    <li>
                        <i className="fas fa-check-square"></i>
                         LAMP, WAMP, Docker, Google Cloud, Kubernetes, PubSub systems
                    </li>
                    <li>
                        <i className="fas fa-check-square"></i>
                        HTML (Twig, Smarty), SEO, CSS, GraphQL, Elasticsearch, RabbitMQ, Redis, MemCache.
                    </li>
                    <li>
                        <i className="fas fa-check-square"></i>
                        Linux(CentOS, Debian, Ubuntu), Windows(XP, Vista, 7, 10)
                    </li>
                </ul>
                <ul>
                    <li>
                        <i className="fas fa-check-square"></i>
                        Git(lab)(CI/CD), SVN
                    </li>
                    <li>
                        <i className="fas fa-check-square"></i>
                        Jira, Jenkins, Postman, Teams, Skype, Zoom, Slack, Empire, Kibana, Confluence, Openshift, Redis Desktop Manager 

                    </li>
                    <li>
                        <i className="fas fa-check-square"></i>
                        PHPUnit, Kahlan, Behat, Cypress
                    </li>
                    <li>
                        <i className="fas fa-check-square"></i>
                        xDebug, Browsers Development Tools Bar, NewRelic, DynaTrace
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default OtherSkills;