export const portfolioData = [
  {
    id: 1,
    name: 'iDGARAGES.COM',
    languages: ['javascript', 'css', 'php'],
    languagesIcons: ['fab fa-js', 'fab fa-css3-alt', 'fab fa-php'],
    source: 'http://www.github.com',
    info: 'In a simple and fast way, you can book an appointment for an intervention as soon as possible, in garages near you.',
    picture: './media/idgarages.com.png'
  },
  {
    id: 2,
    name: 'Maisons du Monde',
    languages: ['php'],
    languagesIcons: ['fab fa-php'],
    source: 'maisonsdumonde.com',
    info: 'Maisons du Monde is based on a network of 336 stores in 9 countries, 12 e-commerce websites and 4 catalogues (Indoor, Outdoor, Junior and BtoB).',
    picture: './media/mdm.com.png'
  },
  {
    id: 3,
    name: 'Lumni',
    languages: ['javascript', 'php', 'css'],
    languagesIcons: ['fab fa-js', 'fab fa-php', 'fab fa-css3-alt'],
    source: 'lumni.fr',
    info: 'A new offer that allows access to culture, knowledge and knowledge. It offers children alone or accompanied to learn differently, extend lessons and understand the world around us.',
    picture: './media/lumni.fr.png'
  },
  {
    id: 4,
    name: 'Let\'s code well',
    languages: ['javascript', 'react'],
    languagesIcons: ['fab fa-js', 'fab fa-react'],
    source: 'letscodewell.com',
    info: '',
    picture: './media/letscodewell.com.png'
  },
  {
    id: 5,
    name: 'A.E.T Trappes',
    languages: ['php', 'wordpress'],
    languagesIcons: ['fab fa-js', 'fa fa-wordpress'],
    source: 'letscodewell.com',
    info: 'The creation of the association responds to a need: to allow children, from an early age, to benefit from a differentiated pedagogy',
    picture: './media/aet.fr.png'
  },
]