import React, { Component } from 'react';
import ProgressBar from './ProgressBar';

class Languages extends Component {
    state = {
        languages: [
            { id: 1, value: "PHP (7.x, 5.x)", xp: 10.0 },
            { id: 2, value: "Javascript(ES5, ES6), AJAX, Jquery", xp: 7.0 },
            { id: 3, value: "MySQL", xp: 7.0 },
            { id: 4, value: "MongoDB", xp: 5.0 },
            { id: 5, value: "HTML, CSS", xp: 7.0 },
            { id: 6, value: "NodeJs", xp: 2.0 },
            { id: 7, value: "React", xp: 1.0 }
        ],
        frameworks: [
            { id: 1, value: "Symfony (1.4, ... 5)", xp: 7.0 },
            { id: 2, value: "Bootstrap", xp: 4.0 },
            { id: 3, value: "Silex", xp: 5.0 },
            { id: 4, value: "Zend Expressive", xp: 4.0 }
        ],
    };

    render() {
        let { languages, frameworks } = this.state;

        return (
            <div className="languagesFrameworks">
                <ProgressBar
                    languages={languages}
                    className="languagesDisplay"
                    title="languages"
                />

                <ProgressBar
                    languages={frameworks}
                    className="frameworksDisplay"
                    title="frameworks & libraries"
                />
            </div>
        );
    }
}

export default Languages;