import React from 'react';

const Hobbies = () => {
    return (
        <div className="hobbies">
            <h3>Leisure</h3>
            <ul>
                <li className="hobby">
                    <i className="fas fa-running"></i>
                    <span>Runnig & soccer game</span>
                </li>
                <li className="hobby">
                <i class="fa-brands fa-leanpub"></i>
                    <span>Learn and learn new things</span>
                </li>
                <li className="hobby">
                <i class="fa-solid fa-chalkboard-user"></i>
                    <span>perform the technology intelligence</span>
                </li>
            </ul>
        </div>
    );
};

export default Hobbies;